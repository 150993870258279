<template>
  <ui-component-modal
    modalTitle="Add existing location"
    modalSize="large"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :disableSaveButton="!selectedLocation || selectedPackageId === 0"
    :onClickCancel="onClickCancel"
    :onClickSave="addLocation"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <div>
        <p v-if="!selectedLocation" class="subtitle">Search location</p>
        <search-location
          v-if="!selectedLocation"
          @locationClicked="setSelectedLocation"
          :channelAuthorizeOnly="true"
        />
      </div>

      <message
        v-if="showAlreadyAdded"
        :message="'Location is already added to channel'"
        :class="'is-danger'"
      />

      <div v-if="selectedLocation">
        <p class="subtitle">Select package</p>

        <div class="has-margin-bottom">
          <a @click="selectedLocation = null">
            <span class="icon has-text-danger">
              <font-awesome-icon :icon="['fas', 'times']" />
            </span>
            <span>{{ selectedLocation.Name }}</span>
          </a>
        </div>

        <div class="select" :class="{ 'is-loading': isLoadingPackages }">
          <select v-model="selectedPackageId">
            <option value="0">Select a subscription</option>
            <option
              v-for="pckg in pckgs"
              :key="pckg.Id"
              :value="pckg.Id"
              :selected="pckg.Id === selectedPackageId"
              >{{ pckg.Name }}</option
            >
          </select>
        </div>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import locationProvider from '@/providers/location'

const Message = () => import('@/components/UI/Message')
const SearchLocation = () => import('@/components/Locations/SearchLocation')

export default {
  components: {
    message: Message,
    'search-location': SearchLocation,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isLoadingPackages: false,
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      isSearching: false,
      pacakges: [],
      selectedLocation: null,
      selectedPackageId: 0,
      showAlreadyAdded: false,
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
    ...mapState('locationStore', ['locationsPageState']),
    ...mapState('packageStore', ['pckgs']),
  },

  created() {
    this.getAllPackages(this.channel.Id)
  },

  methods: {
    ...mapMutations('locationStore', ['setLocationsPageState']),
    ...mapActions('packageStore', ['getAllPackages']),

    setSelectedLocation(location) {
      if (!this.checkAlreadyAdded(location.Id)) {
        this.selectedLocation = location
      } else {
        this.showAlreadyAdded = true
      }
    },

    addLocation() {
      let self = this

      if (!this.checkAlreadyAdded(this.selectedLocation.Id)) {
        if (!self.isSaving) {
          self.isSaving = true

          locationProvider.methods
            .saveLocationChannel(
              self.selectedLocation.Id,
              self.channel.Id,
              self.selectedPackageId
            )
            .then((response) => {
              if (response.status === 204) {
                self.isSavingSuccess = true

                let locationsPageState = JSON.parse(
                  JSON.stringify(self.locationsPageState)
                )
                self.selectedLocation.CreatedOn = new Date()
                locationsPageState.locations.unshift(self.selectedLocation)
                self.setLocationsPageState(locationsPageState)

                let t = setTimeout(() => {
                  self.onClickCancel()
                  clearTimeout(t)
                }, 1500)
              }
            })
            .catch((error) => {
              self.isSavingError = true
            })
            .finally(() => {
              self.isSaving = true
            })
        }
      }
    },

    checkAlreadyAdded(locationId) {
      let result = false

      let locationIndex = this.locationsPageState.locations.findIndex(
        (l) => l.Id === locationId
      )
      if (locationIndex > -1) {
        result = true
      }

      return result
    },
  },
}
</script>
